import React, { createElement, useCallback, useEffect, useRef, useState } from 'react'
import Content from '../../../components/Content/Content'
import Styles from './blogSettings.module.css'
import { createBlog, getAllBlogs, getAllTopics, getBlogById } from '../../../redux/actions/blogListAction';
import { useDispatch, useSelector } from 'react-redux';
import AddNewBlogModal from './AddNewBlogModal';
import { useDropzone } from 'react-dropzone';
import TextEditor from '../../../components/TextEditor/TextEditor';
import { useParams, useHistory, Link } from 'react-router-dom';
import { imageUpload } from '../../../redux/actions/contentAction';
import MultiSelectDropdown from '../../../components/MultiSeclectDropdown/MultiSelectDropdown';
import { commaSeprator } from '../../../redux/constants/utils';


const pageName = "BlogSetting"

const EditBlog = () => {
  const { id } = useParams();
  const topicList = useSelector((state) => state.blogsTopicsList)
  const history = useHistory();
  const blogList = useSelector((state) => state.blogsList)
  const dispatch = useDispatch()
  const [blogTitle, setBlogTitle] = useState({ value: "", error: "" });
  const [blogStatus, setBlogStatus] = useState(null);
  const [richText, setRichText] = useState({ value: "", error: "" });
  const [blogSubTitle, setBlogSubTitle] = useState({ value: "", error: "" });
  const [showFieldTypeModal, setShowFieldTypeModal] = useState(false);
  const [showAddBlogModal, setShowAddBlogModal] = useState(false);
  const [uploadImagePreview, setUploadImagePreview] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImageError, setUploadImageError] = useState('');
  const [tagOptions, setTagOptions] = useState({ value: [], id: "1", error: "" })
  const [tagOptions1, setTagOptions1] = useState([])
  const [allBlogs, setAllBlogs] = useState([])
  const [relatedPost1, setRelatedPost1] = useState({ label: "Auto Generated", value: -1 })
  const [relatedPost2, setRelatedPost2] = useState({ label: "Auto Generated", value: -1 })
  const [relatedPost3, setRelatedPost3] = useState({ label: "Auto Generated", value: -1 })
  const [blogData, setBlogData] = useState({})
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (id) {
      dispatch(getBlogById(id)).then(result => {
        if (result.data.status) {
          setBlogData(result.data.data)
          setBlogTitle({ ...blogTitle, value: result.data.data.title })
          setBlogSubTitle({ ...blogSubTitle, value: result.data.data.subTitle })
          setRichText({ ...richText, value: result.data.data.richText })
          setBlogStatus(result.data.data.status)
          if (result.data.data.tagList && result.data.data.tagList.length) {
            setTagOptions({
              ...tagOptions, value: result.data.data.tagList.map((item, index) => ({
                label: `${item.title} - ${item.tag}`,
                value: item.topicId
              }))
            })
          } else
            setTagOptions({ ...tagOptions, value: result.data.data.tagList })
          setUploadImagePreview(result.data.data.imageLink)
          setPosts()
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        console.error("Error fetching blog:", err);
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    const pv = localStorage.getItem("preview");
    if (pv) {
      const pvObject = JSON.parse(pv);
      setBlogTitle({ ...blogTitle, value: pvObject.title })
      setBlogSubTitle({ ...blogTitle, value: pvObject.subTitle })
      setRichText({ ...blogTitle, value: pvObject.richText })
      setRelatedPost1(pvObject.rp1)
      setRelatedPost2(pvObject.rp2)
      setRelatedPost3(pvObject.rp3)
      setUploadImagePreview(pvObject.imageLink)
      if (pvObject.imageName) {
        setUploadImage(dataURLtoFile(pvObject.imageLink, pvObject.imageName))
      }
      setTagOptions({
        ...tagOptions, value: pvObject.tagList.map(item => {
          return { value: item.topicId, label: `${item.title} - ${item.tag}` }
        })
      })

    }
    localStorage.removeItem("preview")
  }, []);


  const setPosts = () => {

    if (blogData && allBlogs && allBlogs.length) {
      if (blogData.relatedPost1 !== -1)
        setRelatedPost1(allBlogs.find(item => item.value === blogData.relatedPost1))
      if (blogData.relatedPost2 !== -1)
        setRelatedPost2(allBlogs.find(item => item.value === blogData.relatedPost2))
      if (blogData.relatedPost3 !== -1)
        setRelatedPost3(allBlogs.find(item => item.value === blogData.relatedPost3))
    }
  }
  useEffect(() => {
    dispatch(getAllBlogs(1, 100));
  }, [dispatch]);

  useEffect(() => {
    if (blogList.blogsList && blogList.blogsList.data) {
      const list = [{ label: "Auto Generated", value: -1 }, ...blogList.blogsList.data.map(item => ({ label: item.title, value: item.blogId }))]
      setAllBlogs(list)

    }
  }, [blogList])
  useEffect(() => {
    setPosts()
  }, [allBlogs])



  const getAllTopicsList = () => dispatch(getAllTopics());

  useEffect(() => {
    getAllTopicsList()
  }, [dispatch])



  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles);
  }, []);
  const encodeImageFileAsURL = (files) => {
    var file = files[0];
    setUploadImage(file);
    var reader = new FileReader();
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg,image/png",
    onDrop,
  });
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    if (uploadImage) {
      setUploadImageError('')
    }
  }, [uploadImage])
  const createPreview = () => {
    const pre = {
      title: blogTitle.value,
      subTitle: blogSubTitle.value,
      richText: richText.value,
      relatedPost1Data: { blogId: 1, title: blogTitle.value, imageLink: uploadImagePreview },
      relatedPost2Data: { blogId: 1, title: blogTitle.value, imageLink: uploadImagePreview },
      relatedPost3Data: { blogId: 1, title: blogTitle.value, imageLink: uploadImagePreview },
      rp1: relatedPost1,
      rp2: relatedPost2,
      rp3: relatedPost3,
      imageLink: uploadImagePreview,
      imageName: uploadImage && uploadImage.name ? uploadImage.name : null,
      publishedDate: new Date(),
      tagList: tagOptions.value.map((item, index) => {
        const tg = item.label.split("-")
        return { topicId: item.value, tag: tg[1].trim(), title: tg[0].trim() }
      })
    }
    localStorage.setItem("preview", JSON.stringify(pre))
    history.push('/mwfbi/preview')
  }

  const saveBlog = (preview = false) => {
    if (blogTitle.value === "") {
      setBlogTitle({ ...blogTitle, error: "Please enter the title !" });
      return
    }
    if (blogSubTitle.value === "") {
      setBlogSubTitle({
        ...blogSubTitle,
        error: "Please enter the subtitle !",
      });
      return
    }
    if (tagOptions.value === "Select Tag") {
      setTagOptions({
        ...tagOptions,
        error: "Please select the Tag !",
      });
      return
    }
    if (!uploadImagePreview) {
      setUploadImageError('Please select a image !')
      return;
    }
    const tagsString = tagOptions.value.map(tag => tag?.value).join(',');
    if (preview) {
      createPreview()
      return
    }
    else
      if (uploadImage) {
        imageUpload(uploadImage).then((res) => {
          if (res.data.status) {
            const numericId = parseInt(id);
            setUploadImagePreview(res.data.path);
            // const tagsString = tagOptions.value.map(tag => tag.value).join(',');
            if (id) {
              let content = {
                id: numericId,
                title: blogTitle.value,
                subtitle: blogSubTitle.value,
                tag: tagsString,
                relatedPost1: relatedPost1 ? relatedPost1.value : -1,
                relatedPost2: relatedPost2 ? relatedPost2.value : -1,
                relatedPost3: relatedPost3 ? relatedPost3.value : -1,
                richText: richText.value,
                imageLink: res.data.path,
                status: 'Active',
              }

              createBlog(content).then((result) => {
                if (result.data.status) {
                  alert("Content Saved");
                  history.push('/mwfbi/blog-settings')
                }
              });
            }
            else {
              let content = {
                title: blogTitle.value,
                subtitle: blogSubTitle.value,
                tag: tagsString,
                relatedPost1: relatedPost1 ? relatedPost1.value : -1,
                relatedPost2: relatedPost2 ? relatedPost2.value : -1,
                relatedPost3: relatedPost3 ? relatedPost3.value : -1,
                richText: richText.value,
                imageLink: res.data.path,
                status: 'Active',
              }

              createBlog(content).then((result) => {
                if (result.data.status) {
                  alert("Content Saved");
                  history.push('/mwfbi/blog-settings')
                }
              });;
            }
          }
        })
      }
      else {
        if (id) {
          const numericId = parseInt(id);
          let content = {
            id: numericId,
            title: blogTitle.value,
            subtitle: blogSubTitle.value,
            tag: tagsString,
            relatedPost1: relatedPost1 ? relatedPost1.value : -1,
            relatedPost2: relatedPost2 ? relatedPost2.value : -1,
            relatedPost3: relatedPost3 ? relatedPost3.value : -1,
            richText: richText.value,
            imageLink: uploadImagePreview,
            status: 'Active',
          }

          createBlog(content).then((result) => {
            if (result.data.status) {
              alert("Content Saved");
              history.push('/mwfbi/blog-settings')
            }
          });
        }
        else {
          let content = {
            title: blogTitle.value,
            subtitle: blogSubTitle.value,
            tag: tagsString,
            relatedPost1: -1,
            relatedPost2: -1,
            relatedPost3: -1,
            richText: richText.value,
            imageLink: uploadImagePreview,
            status: 'Active',
          }

          createBlog(content).then((result) => {
            if (result.data.status) {
              alert("Content Saved");
              history.push('/mwfbi/blog-settings')
            }
          });;
        }
      }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveBlog()
  }
  const preview = (e) => {
    e.preventDefault();
    saveBlog(true)
  }

  const handlePublish = (e) => {
    e.preventDefault();
    if (blogTitle.value === "") {
      setBlogTitle({ ...blogTitle, error: "Please enter the title !" });
      return
    }
    if (blogSubTitle.value === "") {
      setBlogSubTitle({
        ...blogSubTitle,
        error: "Please enter the subtitle !",
      });
      return
    }
    if (tagOptions.value === "Select Tag") {
      setTagOptions({
        ...tagOptions,
        error: "Please select the Tag !",
      });
      return
    }
    if (!uploadImagePreview) {
      setUploadImageError('Please select a image !')
      return;
    }
    const tagsString = tagOptions.value.map(tag => tag.value).join(',');
    if (uploadImage) {
      imageUpload(uploadImage).then((res) => {
        if (res.data.status) {
          setUploadImagePreview(res.data.path);
          if (id) {
            const numericId = parseInt(id);
            let content = {
              id: numericId,
              title: blogTitle.value,
              subtitle: blogSubTitle.value,
              tag: tagsString,
              relatedPost1: relatedPost1 ? relatedPost1.value : -1,
              relatedPost2: relatedPost2 ? relatedPost2.value : -1,
              relatedPost3: relatedPost3 ? relatedPost3.value : -1,
              richText: richText.value,
              imageLink: res.data.path,
              status: 'Published',
            }
            createBlog(content).then((result) => {
              if (result.data.status) {
                alert("Content Saved");
                history.push('/mwfbi/blog-settings')
              }
            });
          }
          else {
            let content = {
              title: blogTitle.value,
              subtitle: blogSubTitle.value,
              tag: tagsString,
              relatedPost1: relatedPost1 ? relatedPost1.value : -1,
              relatedPost2: relatedPost2 ? relatedPost2.value : -1,
              relatedPost3: relatedPost3 ? relatedPost3.value : -1,
              richText: richText.value,
              imageLink: res.data.path,
              status: 'Published',
            }
            createBlog(content).then((result) => {
              if (result.data.status) {
                alert("Content Saved");
                history.push('/mwfbi/blog-settings')
              }
            });;
          }
        }
      })
    }
    else {
      // const tagsString = tagOptions.value.map(tag => tag?.value).join(',');
      if (id) {
        const numericId = parseInt(id);
        let content = {
          id: numericId,
          title: blogTitle.value,
          subtitle: blogSubTitle.value,
          tag: tagsString,
          relatedPost1: relatedPost1 ? relatedPost1.value : -1,
          relatedPost2: relatedPost2 ? relatedPost2.value : -1,
          relatedPost3: relatedPost3 ? relatedPost3.value : -1,
          richText: richText.value,
          imageLink: uploadImagePreview,
          status: 'Published',
        }
        createBlog(content).then((result) => {
          if (result.data.status) {
            alert("Content Saved");
            history.push('/mwfbi/blog-settings')
          }
        });
      }
      else {
        let content = {
          title: blogTitle.value,
          subtitle: blogSubTitle.value,
          tag: tagsString,
          relatedPost1: relatedPost1 ? relatedPost1.value : -1,
          relatedPost2: relatedPost2 ? relatedPost2.value : -1,
          relatedPost3: relatedPost3 ? relatedPost3.value : -1,
          richText: richText.value,
          imageLink: uploadImagePreview,
          status: 'Published',
        }
        createBlog(content).then((result) => {
          if (result.data.status) {
            alert("Content Saved");
            history.push('/mwfbi/blog-settings')
          }
        });;
      }
    }
    // createBlog(content);
  }

  const dropDownValues = (item) => {
    let arr_obj = [];
    commaSeprator(item.FieldValue).forEach((data) => {
      let obj = { label: data, value: data };
      arr_obj.push(obj);
    });
    return arr_obj;
  };


  return (
    <Content headerFooterMenu='mountain-west'>
      <div className={Styles.container}>
        <div className={Styles.mainForm}>
          <span className={Styles.formTitle}>{id ? 'Edit Blog' : 'Create Blog'}</span>
          <div className={Styles.subform}>
            <div className={Styles.inputContainer}>
              <div className={Styles.inputBox}>
                <span className={Styles.inputTitle}>Blog Title</span>
                <input
                  type="text"
                  style={{ fontFamily: 'inherit' }}
                  value={blogTitle.value}
                  onChange={(e) => setBlogTitle({ ...blogTitle, value: e.target.value })}
                  placeholder='Enter the blog title'
                  className={Styles.input} />
                {blogTitle.error && <p style={{ color: 'red', marginTop: '0.2rem', fontSize: '1.2rem' }}>{blogTitle.error}</p>}
              </div>
              <div className={Styles.inputBox}>
                <span className={Styles.inputTitle}>Blog Sub Title</span>
                {/* <input
                  type="text"
                  value={blogSubTitle.value}
                  onChange={(e) => setBlogSubTitle({ ...blogSubTitle, value: e.target.value })}
                  placeholder='Enter the sub title'
                  className={Styles.input} /> */}
                <textarea
                  type="text"
                  style={{ fontFamily: 'inherit' }}
                  value={blogSubTitle.value}
                  onChange={(e) => setBlogSubTitle({ ...blogSubTitle, value: e.target.value })}
                  placeholder='Enter the sub title'
                  className={Styles.input_textarea} />

                {blogSubTitle.error && <p style={{ color: 'red', marginTop: '0.2rem', fontSize: '1.2rem' }}>{blogSubTitle.error}</p>}
              </div>
              <div className={Styles.inputBox} style={{ paddingTop: '2rem' }}>
                <span className={Styles.inputTitle} style={{ paddingBottom: '0rem' }}>Select Tags</span>

                <MultiSelectDropdown
                  key={"tagsselect"}
                  className={Styles.multi_dropdown}
                  options={topicList?.topicsList?.data
                    ?.filter(item => item.status)
                    .map((item, index) => ({
                      label: `${item.mainTitle} - ${item.tag}`,
                      value: item.topicId
                    }))
                  }
                  value={tagOptions.value}
                  onChange={(selected) => {
                    setTagOptions({ ...tagOptions, value: selected, error: '' })
                    setTagOptions1(selected)
                  }
                  }
                />

                {tagOptions.error && <p style={{ color: 'red', marginTop: '0.2rem', fontSize: '1.2rem' }}>{tagOptions.error}</p>}
              </div>
            </div>
            <div className={Styles.buttonContainer}>
              <div
                className={Styles.upload_border}
                {...getRootProps()}
                style={{ marginTop: "-1rem" }}
              >
                <input {...getInputProps()} />
                <label>Click or Drag a image to this area to upload</label>
                {uploadImagePreview === "" ? (
                  <svg className="modal_upload__icon-dark">
                    <use xlinkHref="/sprite.svg#icon-cloud" />
                  </svg>
                ) : (
                  <img
                    height={150}
                    width={190}
                    src={uploadImagePreview}
                    style={{
                      position: 'absolute',
                      top: '27rem',
                      marginTop: "0rem",
                      alignSelf: "center",
                    }}

                  />

                )}
                {uploadImageError && <p style={{ color: 'red', marginTop: '0.2rem', fontSize: '1.2rem' }}>{uploadImageError}</p>}
              </div>

              {/* <button onClick={onBlogSettingSave}>Save</button>
              <button onClick={(e) => {
                e.preventDefault();
                if (blogSettingData) {
                  setBlogTitle(blogSettingData.title.value)
                  setBlogSubTitle(blogSettingData.subtitle.value)
                }
              }}>Cancel</button> */}
            </div>
          </div>
        </div>
        <div className={Styles.searchContainer}>
          <TextEditor
            placeholder='Add your text here...'
            value={richText.value}
            onContentChange={(content) => {
              setRichText({ value: content, error: '' })
            }}
            errorMessage={richText.error}
            style={{ height: '55rem' }}
          />

          {/* <Slate editor={editor} initialValue={initialValue} /> */}
          {/* {!loading && <BodyTextEditor
            value={richText.value}
            setValue={bodyText => setRichText({ value: bodyText, error: '' })}
          />
          } */}
        </div>
        <div className={Styles.searchContainer1}>
          <div className={Styles.mainForm}>
            <span className={Styles.formTitle}>Related Posts</span>
            <div className={Styles.subform}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputBox1}>
                  <span className={Styles.inputTitle}>Posts</span>
                  <MultiSelectDropdown
                    key={"related1"}
                    isMulti={false}
                    className={Styles.multi_dropdown}
                    options={allBlogs}
                    value={relatedPost1}
                    placeHolder={"Related Post 1"}
                    onChange={(selected) => {
                      setRelatedPost1(selected)
                    }
                    }
                  />
                </div>
                <div className={Styles.inputBox1}>
                  <MultiSelectDropdown
                    key={"related2"}
                    isMulti={false}
                    className={Styles.multi_dropdown}
                    options={allBlogs}
                    value={relatedPost2}
                    placeHolder={"Related Post 2"}
                    onChange={(selected) => {
                      setRelatedPost2(selected)
                    }
                    }
                  />
                </div>
                <div className={Styles.inputBox1}>
                  <MultiSelectDropdown
                    key={"related3"}
                    isMulti={false}
                    placeHolder={"Related Post 3"}
                    className={Styles.multi_dropdown}
                    options={allBlogs}
                    value={relatedPost3}
                    onChange={(selected) => {
                      setRelatedPost3(selected)
                    }
                    }
                  />
                </div>
              </div>
              <div className={Styles.buttonContainer}>


              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="modal_inner_content" style={{ marginTop: '4rem' }}>
            <div className="modal_form_container" style={{ height: 'auto' }}>
              <div className={Styles.buttonContainer}>
                <button onClick={preview}>Preview</button>
                {(!id || blogStatus !== "Published") && <button onClick={(e) => handleSubmit(e)}>Save</button>}
                <button onClick={(e) => handlePublish(e)}>{(id && blogStatus === "Published")?'Save & Publish':'Publish'}</button>
                <Link to={`/mwfbi/blog-settings`}>
                  <button>Cancel</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <AddNewBlogModal
          // blogsList={blogsList}
          showModal={showAddBlogModal}
          setShowModal={setShowAddBlogModal}
        />
      </div>
    </Content>
  )
}

export default EditBlog;
